 .torqueWrapper {
   margin: auto;
   margin-top: 5rem;
   width: 82rem;
   overflow-y: hidden;
   overflow-x: hidden;
 }

 .torquingItHeader {
   padding: 20px;
   width: 100%;
 }

 .torqueText {
   color: white;
   font-weight: bold;
 }

 .torqueCard {
   display: grid;
   grid-template-areas:
     "unit unit unit unit unit unit unit unit unit unit unit unit"
     "target target target target diameter diameter diameter diameter engagement engagement engagement engagement"
     "outer outer outer outer image image image image inner inner inner inner"
     "tensile-strength tensile-strength tensile-strength tensile-ratio tensile-ratio tensile-ratio head head head thread thread thread";
   background-color: #f5f5f5;
   width: 100%;
   border-radius: 10px;
   margin: auto;
   margin-top: 1rem;
   padding: 2rem;
   align-items: center;
   // grid-row-gap: 1rem;
   column-gap: 1rem;
 }

 .torqueCardWrapper {
   background-color: #3c649b;
   margin-bottom: 75px;
   padding: 20px;
   width: 100%;
   border-radius: 10px;
 }

 .torqueImage {
   display: grid;
   grid-area: image;
   width: 24rem;
   padding: 0 1.5rem 0 1.5rem;
 }

 .torqueFieldWrapper {
   border: solid #052c45 1px;
   margin: auto;
   border-radius: 10px;
   padding: .5rem;
   text-align: center;
   width: 18rem;
 }

 .torqueRangedFieldWrapper {
   display: grid;
 }

 .torqueRangedFieldHeader {
   grid-row: 1 / 2;
 }

 .torqueRangedFieldContentRow1 {
   grid-row: 2 / 3;
   height: 50px;
   white-space: nowrap;
 }

 .torqueRangedFieldContentRow2 {
   grid-row: 3 / 4;
   height: 50px;
   white-space: nowrap;
 }

 .torqueHeader {
   font-size: .75rem;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-weight: bold;
   color: #052c45;
   margin-bottom: 0.5rem;
   margin-top: 0.5rem;
 }

 .torqueMinWrapper {
   display: inline;
 }

 .inputLabel {
   color: #052c45;
 }

 .targetTorqueWrapper {
   display: grid;
   grid-area: target;
   white-space: nowrap;
   margin: auto;
 }

 .targetTorqueHeader {
   grid-column: 1 / 2;
   grid-row: 1 / 2;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-weight: bold;
 }

 .selectTargetTorque {
   grid-column: 1 / 2;
   grid-row: 2 / 3;
 }

 .unitsTorqueWrapper {
   display: grid;
   grid-area: unit;
   margin: auto;
   margin-bottom: 1.5rem;
   text-align: center;
   grid-template-columns: 2fr 1fr 2fr;
   grid-template-rows: 1 / 2;
 }

 .torqueUnitHeader {
   display: grid;
   grid-column: 1 / 4;
   grid-row: 1 / 1;
   font-size: 1rem;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-weight: bold;
   color: #052c45;
   margin-bottom: 1rem;
   margin-top: 0.5rem;
 }

 .torqueUnitImperial {
   display: grid;
   grid-column: 1 / 2;
   grid-row: 2 / 2;
   font-size: .75rem;
   font-weight: bold;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   color: #052c45;
   margin-bottom: 0.5rem;
   margin-top: 0.5rem;
 }

 .torqueUnitMetric {
   display: grid;
   grid-column: 3 / 4;
   grid-row: 2 / 2;
   font-size: .75rem;
   font-weight: bold;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   color: #052c45;
   margin-bottom: 0.5rem;
   margin-top: 0.5rem;
 }

 .torqueUnitSwitch {
   display: grid;
   grid-column: 2 / 3;
   grid-row: 2 / 2;
 }

 .tensileStrengthWrapper {
   grid-area: tensile-strength;
 }

 .yieldRatioWrapper {
   grid-area: tensile-ratio;
 }

 .torqueNominalWrapper {
   grid-area: diameter;
 }

 .shankDiameterHeader {
   grid-column: 1 / 2;
   grid-row: 1 / 2;
 }

 .selectShankDiameter {
   grid-column: 1 / 2;
   grid-row: 2 / 3;
 }

 .torqueEngagementWrapper {
   display: grid;
   grid-area: engagement;
   grid-template-columns: 1fr 1fr;
 }

 .engagementHeader {
   grid-column: 1 / 2;
   grid-row: 1 / 2;
 }

 .selectEngagement {
   grid-column: 1 / 2;
   grid-row: 2 / 3;
   width: 275px;
   // height: 50px;
 }

 .torqueThreadFrictionWrapper {
   grid-area: thread;
 }

 .torqueOuterDiameterWrapper {
   display: grid;
   grid-area: outer;
   white-space: nowrap;

 }

 .outerDiameterHeader {
   grid-column: 1 / 2;
   grid-row: 1 / 2;
 }

 .selectOuterDiameter {
   grid-row: 2 / 3;
 }

 .torqueInnerDiameterWrapper {
   display: grid;
   grid-area: inner;
   white-space: nowrap;
 }

 .innerDiameterHeader {
   grid-column: 1 / 2;
   grid-row: 1 / 2;
 }

 .selectInnerDiameter {
   grid-row: 2 / 3;
 }

 .torqueHeadFrictionWrapper {
   grid-area: head;
 }

 //**********************************************

 .torqueSubmitWrapper {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
 }

 .torqueSubmit {
   display: grid;
   grid-column: 4 / 5;
   max-width: 10rem;
   margin-top: 2rem;
 }

 //********************************************

 .torqueCalculatorResultsWrapper {
   background-color: #3c649b;
   padding: 20px;
   width: 100%;
   border-radius: 10px;
 }

 .torqueCalculatorResultsContainer {
   display: grid;
   grid-template-columns: 1fr 1fr;
   background-color: #f5f5f5;
   width: 100%;
   height: 30rem;
   border-radius: 10px;
   margin: auto;
   margin-bottom: 25px;
   padding: 25px;
 }

 .torqueCalculatorResultsContainer h2 {
   grid-column: 1 / -1;
 }

 .torqueCalculatorInputSummaryContainer {
   display: grid;
   grid-template-columns: 1fr 1fr;
   background-color: #f5f5f5;
   width: 100%;
   height: 30rem;
   border-radius: 10px;
   margin: auto;
   margin-bottom: 25px;
   padding: 20px;
 }

 .torqueCalculatorResultsWrapper dl {
   display: grid;
   grid-template-columns: 1.5fr 1fr;
   margin-left: 20px;
 }

 .torqueCalculatorResultsWrapper dd {
   white-space: nowrap;
   font-size: 1rem;
 }

 .torqueCalculatorResultsWrapper dt {
   margin-right: 20px;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-size: 1rem;
   white-space: nowrap;
   margin-bottom: 10px;
   color: #3c649b;
 }

 .torqueCalculatorResultsWrapper dt:after {
   content: ":";
 }

 .torqueImage2 {
   width: 75%;
   position: relative;
   top: -50px;
   margin: auto;
 }

 .torqueImage3 {
   width: 75%;
   position: relative;
   margin: auto;
 }

 .resultsHeader {
   margin-bottom: 50px;
   color: #3c649b;
 }

 .torqueCalculatorUnitString {
   margin-left: 0.5rem;
   color: #052c45;
 }

 .with-units {
   max-width: calc(100% - 7.5rem);
 }

 .torqueCalculatorFieldErrorMessage {
   color: red;
   margin-bottom: 0;
   font-size: 0.85rem;
   font-weight: bold;
 }

 .inputError {
   border: 2px solid red;
 }

 @media screen and (max-width: 1725px) {}