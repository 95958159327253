@import "variables";

.designItWrapper {
  display: grid;
  background-color: white;
  grid-template-columns: repeat(10, 1fr);
  width: 100%;
  margin: auto;
  gap: 30px;
  margin-top: 5rem;
}



.designItHeader {
  display: grid;
  grid-column: 2 / 10;
  grid-row: 1 / 1;
  font-kerning: normal;
  font-size: 2.75rem;
  background-image: $FFgrad1;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top-right-radius: 25px;
  position: relative;
  width: 100%;
}

.designItBannerText {
  display: grid;
  grid-column: 2 / 10;
  grid-row: 2 / 2;
  color: black;
  font-size: 2rem;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.designItCard1 {
  display: grid;
  grid-column: 2 /6;
  grid-row: 3 /3;
  background-color: white;
  margin: auto;
  min-height: 35rem;
  border: solid black 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.designItCard2 {
  display: grid;
  grid-column: 6 /10;
  grid-row: 3 /3;
  background-color: white;
  margin: auto;
  min-height: 35rem;
  border: solid black 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.designItCard3 {
  display: grid;
  grid-column: 2 /6;
  grid-row: 4 /4;
  background-color: white;
  margin: auto;
  min-height: 35rem;
  border: solid black 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
  rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }


.designItCard4 {
display: grid;
grid-column: 6 /10;
grid-row: 4 /4;
background-color: white;
margin: auto;
min-height: 35rem;
border: solid black 5px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.designItBottomRow {
  margin-bottom: 3rem;
}

.card1Background {
  border-bottom: solid black 4px;
  background-color: #582b7d6a;
  text-align: center;
  justify-content: center;
}

.card2Background {
  border-bottom: solid black 4px;
  background-color: #3daee254;
  text-align: center;
  justify-content: center;
}

.card3Background {
  border-bottom: solid black 4px;
  background-color: #3c649b51;
  text-align: center;
  justify-content: center;
}

.card4Background {
  border-bottom: solid black 4px;
  background-color: #17e7d954;
  text-align: center;
  justify-content: center;
}

.designItCardHeader {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.designItCardText {
  font-size: 1.5rem;
  background-color: white;
  text-align: center;
  margin-top: 3rem;
  max-width: 40ch;
  padding: 1rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.cardImage {
  margin-bottom: 1rem;
  margin-top: 1rem;
}


@media screen and (max-width: 1065px) {

  .designItCard1 {
      display: grid;
      grid-column: 2 /10;
      grid-row: 3 /3;
    }
  
    .designItCard2 {
      display: grid;
      grid-column: 2 /10;
      grid-row: 4 /4;
    }
  
    .designItCard3 {
      display: grid;
      grid-column: 2 /10;
      grid-row: 5 /5;
    }
  
  
    .designItCard4 {
      display: grid;
      grid-column: 2 /10;
      grid-row: 6 /6;
    }

}
