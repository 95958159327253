@import "variables";

// .learningIt-courses-wrapper {
//     width: 100%;
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     margin-top: 3rem;
//     margin-bottom: 5rem;
// }

// .learningIt-courses-header {
//     display: grid;
//     grid-column: 1 / 3;
//     grid-row: 1 /2;
//     color: white;
//     text-align: center;
//     margin-bottom: 2.5rem;

// }

// .learningIt-item-wrapper {
//     display: grid;
//     grid-template-rows: 1fr 1fr;
//     margin: auto;
//     margin-top: 2rem;
//     margin-bottom: 2rem;
//     padding: 2rem;
//     text-align: center;
//     width: 13rem;
//     height: 13rem;
//     grid-auto-flow: row;
//     justify-items: center;
//     align-items: center;
//     background-color: white;
//     border: 4px solid #1dc5d9;
//     border-radius: 50%;
// }

// .learningIt-item-text {
//     display: block;
//     color: black;
//     font-weight: bold;
// }

.learningIt-header {
  width: 100%;
  // margin-left: -5rem;
}

// .intro-course-column-1 {
//     display: grid;
//     grid-column: 1 / 2;
//     grid-row: 2 /3;
// }

// .intro-course-column-2 {
//     display: grid;
//     grid-column: 2 / 3;
//     grid-row: 2 /3;
// }

// ***********
// Learn It
// ***********

$learnitwidth: 70%;

 #learnIt {
  // width: 100vw; // @Kai - why isn't this full width?
  // width: $learnitwidth;
  max-width: unset;
  margin: 0 auto;
  margin-top: 6rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  font-size: 2rem;
  padding: 1rem;
  position: relative;
  padding-bottom: 100px;
}

#learnItHeading {
  height: 240px;
  background-image: url("../images/screws-bolts-stepan-kulyk-01.png");
  background-size: 960px;
  padding-top: 160px;
  padding-left: 25px;
  font-size: 150%;
  cursor: pointer;
}

.learnItGrid {
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 25px;
  padding: 50px 25px 25px 25px;
  margin: 0 auto;
  //   padding-left: 40px;
}

.learnItTitle {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 26pt;
  height: 45px;
  width: 100%;
  background-color: $FFblu0;
  // border-radius: 0px 25px 25px 0px;
  color: white;
  font-weight: bold;
  font-size: 70%;
  padding-left: 80px;
  padding-top: 5px;
  margin-top: 25px;
  margin-bottom: 2rem;
  // margin-left: 3.5rem;
  position: relative;
  z-index: 2; //@Kai why isn't this working?
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}

.learnItTopic {
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
  padding: 35px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  justify-items: center;
  align-items: center;
  text-align: center;
  color: black;
  background-color: white;
  border: 4px solid #1dc5d9;
  border-radius: 50%;
  line-height: 2.3rem;
}

.learnItImage {
  // border-top-right-radius: 25px;
  height: 5.5rem;
  width: 8.5rem;
  // margin-left: -10px;
  // margin-top: -10px;
  margin: auto;
}

.learnItImageAdhesives {
  height: 7.5rem;
  width: 5.5rem;
}

.learnItImageBiw {
  height: 4rem;
  width: 11rem;
  margin: auto;
  margin-top: 1rem;

}

.bodyInWhiteLearnitTopic {
  font-size: 1.4rem;
}

.learnItImagePlasticClips {
    height: 5.5rem;
    width: 5.5rem;
    margin: auto;
}

.learnItImageRivets {
  height: 5.5rem;
  width: 6.5rem;
  margin: auto;
}

.learnItImageLockbolts {
  height: 2rem;
  width: 9.9rem;
  margin: auto;
  margin-top: 3rem;
}

.learnItImageFinishes {
  height: 6.5rem;
  width: 6.5rem;
  margin: auto;
  margin-bottom: -.7rem;
}

.learnItImageNuts {
  height: 6.5rem;
  width: 6.5rem;
  margin: auto;
}

.learnItImageTapping {
  height: 4.5rem;
  width: 8.5rem;
  margin: auto;
  margin-top: 1rem;
}

.learnItImageScrews {
  height: 3.8rem;
  width: 10.4rem;
  margin: auto;
  margin-top: 1.5rem;
}

.finishesText {
  font-size: 1.4rem;
}

.learnItArticle {
  width: $learnitwidth;
  display: grid;

  padding: 25px 40px 40px 60px;
}

.learnItArticleImages {
  float: right; //@Kai how to float image to the right with text wrapping?
  display: grid;
  gap: 15px;
  padding-left: 30px;
  padding-bottom: 30px;
  max-width: 20rem;
  margin-right: 3rem;
}

.learnItParagraph {
  padding-bottom: 30px;
  margin-left: 3rem;
}

.learnItIcon {
  width: 50px;
  height: 50px;
  background-color: $FFblu2;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: .5em;
  fill: currentColor;
  color: white;
}

.learnItArticleContent {
  font-family: $open-sans;
  font-size: 12pt;
  color: $FFblu1;
  background-color: white;
  // padding: 45px;
  padding-top: 75px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  width: 100%;
}

.learnItSubHeading {
  color: $FFblu2;
  position: relative;
  padding-bottom: 15px;
  padding-left: 75px;
}

.learnItArticleImage {
  width: 300px;
  margin: 0 auto;
}

.smallerLearnitTopic {
  font-size: 1.3rem;
}


.learnItBackButton {
  position: absolute;
  bottom: 0em;
  right: 3rem;
}

.trainingTopic {
  border-top-right-radius: 25px;
  height: 120px;
  padding: 10px;
  cursor: pointer;
  margin: 2rem 0rem;

  //margin-bottom: 25px;
}

.trainingTopicTitle {
  color: $FFblu1;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1.5rem;
}

.trainingTopicTitle:hover {
  color: $FFblu2;
}

.trainingImage {
  border-top-right-radius: 25px;
  height: 120px;
  width: 200px;
  margin-left: -10px;
  margin-top: -10px;
}

//**************************************************//

@media (max-width: 1170px) {
   .learnItGrid {
    display: grid;
    width: auto;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 25px;
    padding: 50px 25px 25px 25px;
    margin: 0 auto;
    //   padding-left: 40px;
  }

   .learnItTitle {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    height: 45px;
    width: 100%;
    background-color: $FFblu0;
    margin: auto;
    // border-radius: 0px 25px 25px 0px;
    color: white;
    font-weight: bold;
    font-size: 60%;
    position: relative;
    z-index: 2; //@Kai why isn't this working?
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  }
}

//**************************************************//

@media (max-width: 750px) {
   .learnItGrid {
    display: grid;
    width: auto;
    grid-template-columns: 1fr ;
    grid-column-gap: 30px;
    grid-row-gap: 25px;
    padding: 25px 25px 25px 25px;
    margin: 0 auto;
    //   padding-left: 40px;
  }

   .learnItTitle {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20pt;
    height: 45px;
    width: 100%;
    background-color: $FFblu0;
    // border-radius: 0px 25px 25px 0px;
    color: white;
    font-weight: bold;
    font-size: 45%;
    padding-left: 18px;
    padding-top: 5px;
    margin-top: 25px;
    margin-bottom: 2rem;
    // margin-left: 2.5rem;
    position: relative;
    z-index: 2; //@Kai why isn't this working?
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  }

   .learnItTopic {
    border-radius: 50%;
    height: 15rem;
    width:15rem;
    padding: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    justify-items: center;
    align-items: center;
    text-align: center;
    color: black;
    background-color: white;
    border: 4px solid #1dc5d9;
    border-radius: 50%;
    line-height: 1rem;
    font-size: 1.5rem;
  }

   .learnItImage {
    // border-top-right-radius: 25px;
    height: 7.5rem;
    width: 7.5rem;
    // margin-left: -10px;
    // margin-top: -10px;
    margin: auto;
    margin-top: 18px;
    // max-width: 5rem;
  }

  // .learnItImageBIW {
  //   height: 4.5rem;
  //     width: 5rem;
  // }

    .bodyInWhiteLearnitTopic {
      font-size: 1.4rem;
      line-height: 1.3rem;
    }
}
