.missionStatementWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  width: 40%;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 3rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  justify-content: center;
}

.missionPic {
  display: grid;
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  width: 50%;
  margin: auto;
  margin-bottom: 1rem;
}

.missionItemPic {
  width: 62px;
  height: 62px;
  margin: auto;
  margin-bottom: 0;
  // padding-top: .5rem;
}

.missionStatementItemWrapper {
  display: grid;
  background-color: white;
  width: 23rem;
  height: 23rem;
  margin: auto;
  border-radius: 50%;
}

.missionItemTitle {
  font-size: 1.2rem;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  color: black;
}

.missionItemText {
  font-size: 0.9rem;
  padding: 0.5rem;
  width: 30ch;
  margin: auto;
  margin-top: 0;
  color: black;
  text-align: center;
}

.missionWrapper {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  border: 10px solid rgb(167, 22, 22);
  border-radius: 50%;
}

.visionWrapper {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  border: 10px solid rgb(58, 58, 247);
  border-radius: 50%;
}

.valuesWrapper {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  border: 10px solid yellow;
  border-radius: 50%;
  white-space: pre-line;
}


//*************************************************//

@media (max-width: 975px) {
  .missionStatementItemWrapper {
    display: grid;
    background-color: white;
    width: 23rem;
    height: 23rem;
    margin: auto;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
  .missionStatementWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    width: 100%;
    margin: auto;
    margin-top: 8rem;
    margin-bottom: 3rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    justify-content: center;
  }

  .missionPic {
    display: grid;
    margin: auto;
    width: 23rem;
    margin-bottom: 1rem;
  }

  .missionWrapper {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    border: 10px solid rgb(167, 22, 22);
    border-radius: 50%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .visionWrapper {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
    border: 10px solid rgb(58, 58, 247);
    border-radius: 50%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .valuesWrapper {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    border: 10px solid yellow;
    border-radius: 50%;
    margin: auto;
  }
}

//**********************************//

@media (max-width: 700px) {
  .missionStatementItemWrapper {
    display: grid;
    background-color: white;
    width: 25rem;
    height: 25rem;
    margin: auto;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
  .missionStatementWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    width: 100%;
    margin: auto;
    margin-top: 8rem;
    margin-bottom: 3rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    justify-content: center;
  }

  .missionPic {
    display: none;
    margin: auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .missionItemText {
    font-size: 0.8rem;
    max-width: 30ch;
  }

  .missionStatementItemWrapper {
    display: grid;
    background-color: white;
    width: 18rem;
    height: 18rem;
    margin: auto;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
}
