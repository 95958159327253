@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

$happy-monkey: "Happy Monkey", cursive;
$FFrainbow: linear-gradient(
  to right,
  #052c45,
  #052c45,
  #582b7d,
  #582b7d,
  #0a6b7c,
  #3c649b,
  #3c649b
);

.wrapper {
  display: grid;
  background-color: white;
  grid-template-columns: repeat(17, 1fr);
  width: 100%;
  max-width: 120rem;
  margin: auto;
  margin-top: 2rem;
}

.webinar {
  grid-column: 1 / 18;
  grid-row: 1 / 2;
}

.liveStorm {
  margin-bottom: -5rem;
}

.row2H {
  grid-column: 1 / 18;
  grid-row: 2 / 3;
  color: black;
  margin: auto;
  text-align: center;
  font-family: $happy-monkey;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
}

.row3Ah {
  grid-row: 3 / 4;
  grid-column: 2 / 8;
  height: 354px;
  width: 250px;
  background-image: url(../images/fadePic1.png);
  background-repeat: no-repeat;
  object-fit: cover;
  transform: rotate(320deg);
  margin: auto;
  overflow: hidden;
}

.dots {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

@keyframes slide {
  0%,
  100% {
    transform: translate(0, -50%);
  }
  50% {
    transform: translate(0, 0);
  }
}

.whiteFadeOverlay {
  background-image: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 40%,
    rgba(255, 255, 255, 0) 50%
  );
  width: 100%;
  height: 300%;
  animation: slide 5s infinite;
}

.row3Bh {
  margin: auto;
  grid-row: 3 / 4;
  grid-column: 8 / 17;
  font-size: 1.5rem;
  max-width: 50ch;
  font-weight: bold;
}



.row4H {
  grid-row: 4 / 5;
  grid-column: 8 / 17;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 5rem;
  z-index: 2;
}

.getStartedText {
  margin: auto;
  font-weight: bold;
}

.getStartedBlue {
  color: #3c649b;
  font-size: 2.2rem;
  text-align: center;
}

.getStartedWhite {
  color: black;
  max-width: 55ch;

  font-size: 2.2rem;
}

.getStarted2Blue {
  color: #3c649b;
  max-width: 35ch;
  margin: auto;
}

.getStarted2White {
  color: black;
}

.row5H {
  grid-row: 5 / 6;
  grid-column: 2 / 17;
  text-align: center;
  margin: auto;
  font-size: 2rem;
  max-width: 40ch;
  margin-bottom: 10rem;
  margin-top: 3rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.buttonSpacer {
  color: #052c45;
  margin-left: 10rem;
}

.buttonSpacer2 {
  color: #052c45;
  margin-left: 2rem;
}

.row6Ah {
  grid-column: 2 / 9;
  grid-row: 6 / 7;
  text-align: center;
  display: grid;
  background-color: white;
  margin-bottom: 5rem;
}

.seoText1 {
  margin: auto;
  font-family: $happy-monkey;
  font-size: 1.8rem;
  // max-width: 18ch;
  margin-bottom: 1rem;
  font-weight: bold;
  color: black;
}

.seoText2 {
  text-overflow: inherit;
  // max-width: 40ch;
  margin: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  color: black;
  text-align: left;
  margin-top: .2rem;
  font-weight: normal;
}

.block2ul {
  font-size: 1.2rem;
  font-weight: bold;
}

.row6Bh {
  grid-column: 10 / 17;
  grid-row: 6 / 7;
  max-height: 38rem;
  text-align: center;
  display: grid;
  padding: 2rem;
  background-color: #0a6b7c;
  margin-bottom: 10rem;
  margin-top: 5rem;
}



.slideShowContainer {
  background-color: white;
  object-fit: contain;
}

.ShowPic {
  margin: auto;
  object-fit: contain;
}

.carouselStatus {
  display: none !important;
}

.row7H {
  grid-column: 1 / 18;
  grid-row: 7 / 8;
  min-height: 20rem;
  text-align: center;
  display: grid;
  background-color: lightgray;
  margin-top: 5rem;
}

.row7HText {
  margin: auto;
  font-family: $happy-monkey;
  font-size: 3rem;
  max-width: 30ch;
  margin-bottom: 3rem;
  margin-top: 3rem;
  color: black;
}

.whyDemoButton {
  margin-bottom: 5rem;
}

.partenershipTitle {
  text-align: center;
  font-weight: bold;
}

.partenershipImage {
  min-width: 750px;
}

.whyText {
  margin: auto;
  max-width: 18ch;
  text-align: center;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: white;
  object-fit: cover;
  font-style: italic;
}

.row8H {
  display: grid;
  grid-row: 8 / 9;
  grid-column: 1 / 18;
  justify-content: space-evenly;
  background-color: lightgray;
}

.row8A {
  grid-row: 8 / 9;
  grid-column: 1 / 6;
  margin-bottom: 5rem;
  background-color: white;
}

.row8B {
  grid-row: 8 / 9;
  grid-column: 6 / 12;
  margin-bottom: 5rem;
  background-color: white;
}

.row8C {
  grid-row: 8 / 9;
  grid-column: 12 / 18;
  margin-bottom: 5rem;
  background-color: white;
}

// .row8D {
//   grid-row: 8 / 9;
//   grid-column: 14 / 17;
//   margin-bottom: 5rem;
//   background-color: white;
// }

.why {
  border: black solid;
  color: black;
  justify-content: center;
  font-size: 1.3rem;
  display: grid;
  border-radius: 2rem;
  background-color: white;
  padding: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 100px 200px -40px,
    rgba(0, 0, 0, 0.3) 0px 60px 120px -60px,
    rgba(10, 37, 64, 0.35) 0px -4px 12px 0px inset;
}

.featureInfoCardHeader {
  position: relative;
  height: 12rem;
}

.joiningItImageContainer {
  position: relative;
  background-color: #9fd4f0;
}

.findingItImageContainer {
  background-color: #9071a8;
}

.learningItImageContainer {
  background-color: #1dc5d985;
}

.designingItImageContainer {
  background-color: #9cafcb;
}

.featureInfoCard {
  border: solid black;
  text-align: center;
  display: grid;
  margin-bottom: 2rem;
  margin-top: 2rem;
  background-color: white;
}

.featureInfoCardRow2:hover {
  transform: scale(1.05);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 100px 200px -40px,
    rgba(0, 0, 0, 0.3) 0px 60px 120px -60px,
    rgba(10, 37, 64, 0.35) 0px -4px 12px 0px inset;
}

.featureInfoCard:hover {
  transform: scale(1.05);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 100px 200px -40px,
    rgba(0, 0, 0, 0.3) 0px 60px 120px -60px,
    rgba(10, 37, 64, 0.35) 0px -4px 12px 0px inset;
}

.featureInfoCardRow2 {
  border: solid black;
  text-align: center;
  display: grid;
  margin-bottom: 2rem;
  background-color: white;
}

.cardLinks:hover {
  color: blue !important;
}

.courseLink {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.courseLink2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.courseLinkDesignIt {
  font-size: 1.3rem;
}

.courseLink:hover {
  margin-bottom: 2rem;
  color: blue !important;
}

.cardImage {
  height: 6rem;
  margin: 1rem;
}

.cardText {
  height: 4rem;
  font-size: 2rem;
  width: 100%;
  color: black;
  font-family: $happy-monkey;
  font-weight: bold;
}

.cardText2 {
  max-width: 30ch;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.cardLink {
  color: blue;
}

.cardLink:hover {
  color: black;
}

.joiningIt {
  display: grid;
  grid-column: 2 / 9;
  grid-row: 9 / 10;
}

.findingIt {
  display: grid;
  grid-column: 10 / 17;
  grid-row: 9 / 10;
}

.learningIt {
  display: grid;
  grid-column: 2 / 9;
  grid-row: 10 /11;
}

.designingIt {
  display: grid;
  grid-column: 10 / 17;
  grid-row: 10 / 11;
}

.demoPreviewImage1 {
  margin-bottom: 2rem;
}

.demoPreviewImage2 {
  margin-top: 2rem;
}

.demoPreviewImage3 {
  margin-bottom: 2rem;
}

.demoPreviewImage4 {
  margin-bottom: 0;
}

.miniCourseButton :hover {
  margin-bottom: 2rem;
  color: blue !important;
}

.cardButton1 {
  position: relative;
  margin-right: 3rem;
  width: 10rem;
  margin-bottom: 1rem;
}

.cardRow {
  margin-bottom: 1.5rem;
}

.cardButton2 {
  position: relative;
  margin-right: 3rem;
  width: 10rem;
}

.linkIcon {
  padding: 0.5rem;
  margin-bottom: 5px;
}

.cardButton3 {
  position: relative;
  margin-right: 3rem;
  width: 10rem;
  margin-bottom: 1rem;
}

.cardButton4 {
  position: relative;
  margin-right: 3rem;
  width: 10rem;
  margin-bottom: 5rem;
}

.designingItCardText {
  margin-bottom: 1rem;
}

.row11H {
  display: grid;
  grid-row: 11 / 12;
  grid-column: 1 / 18;
  background-color: white;
  justify-content: space-between;
  margin-top: 7rem;
  margin-bottom: 3rem;
}

.row12H {
  display: grid;
  grid-row: 12 / 13;
  grid-column: 1 / 18;
  background-color: white;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.review1 {
  display: grid;
  grid-column: 1 / 6 !important;
  grid-row: 7 / 7 !important;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin: auto;
  max-width: 1rem;
  margin-left: 8rem;
}

.review2 {
  display: grid;
  grid-column: 6 / 12 !important;
  grid-row: 7 / 7 !important;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin: 1rem;
  max-width: 1rem;
  margin-left: 5rem;
}

.review3 {
  display: grid;
  grid-column: 1 / 6 !important;
  grid-row: 8 / 8 !important;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin: auto;
  max-width: 1rem;
  margin-left: 8rem;
}

.review4 {
  display: grid;
  grid-column: 6 / 12 !important;
  grid-row: 8 / 8 !important;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin: 1rem;
  max-width: 1rem;
  margin-left: 5rem;
}

.row13Ah {
  grid-column: 10 / 17;
  grid-row: 13 /14;
  text-align: center;
  display: grid;
  background-color: white;
  margin-top: 6rem;
  margin-bottom: 5rem;
}

.skillsText1 {
  margin: auto;
  font-family: $happy-monkey;
  font-size: 2.5rem;
  max-width: 18ch;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: black;
}

.skillsText2 {
  text-overflow: inherit;
  max-width: 25ch;
  margin: auto;
  margin-bottom: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5rem;
  color: black;
  text-align: left;
}

.row13Bh {
  grid-column: 2 / 8;
  grid-row: 13 / 14;
  text-align: center;
  display: grid;
  background-color: teal;
  margin-top: 8rem;
  margin-bottom: 5.5rem;
}

.blackBoardContainer {
  margin: 3rem;
}

.blackBoardPic {
  margin: auto;

  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.getStartedButton :hover {
  transform: scale(1.1);
}

.getStartedButtonSeo :hover {
  transform: scale(1.1);
}

.whyFFDemoButton :hover {
  transform: scale(1.1);
}

.cardButton1 :hover {
  transform: scale(1.1);
}

.cardButton2 :hover {
  transform: scale(1.1);
}

.cardButton3 :hover {
  transform: scale(1.1);
}

.cardButton4 :hover {
  transform: scale(1.1);
}

.hbsptBackground {
  background-color: #052c45;
  margin-bottom: 45rem;
  margin-top: 5rem;
}

.clippingItBackground {
  margin-bottom: 60rem;
}

//**************************************************//

@media (max-width: 1330px) {
  .buttonSpacer2 {
    margin-left: 25rem;
  }

  .reviewImage {
    max-width: 25rem;
  }

  .row13Bh {
    max-height: 30rem;
  }

  .why {
    max-width: 22rem;
  }

  .partnershipImage {
    width: 10rem;
    margin: auto;
  }
}

//**************************************************//

@media (max-width: 1070px) {
  .buttonSpacer {
    margin: 2rem;
  }

  .row2Bh {
    max-height: 10rem;
  }

  .reviewImage {
    max-width: 23rem;
  }
}

//**************************************************//

@media (max-width: 810px) {
  .hubspotForm {
    width: 30rem;
    height: 60rem;
    margin-top: 2rem !important;
    margin: auto;
    margin-bottom: 15rem;
  }

  .row1H {
    grid-column: 3 / 16;
    margin-bottom: 0;
  }

  .row2H {
    margin-top: 0rem;
    padding-left: 5rem;
    padding-right: 5rem;
    font-size: 1rem;
  }

  .row3Ah {
    display: none;
  }

  .row3Bh {
    grid-column: 2 / 17;
    margin-top: 0;
  }

  .row4H {
    grid-column: 1 / 18;
    font-size: 1.7rem;
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .row5Ah {
    grid-row: 5 / 6;
    grid-column: 2 / 17;
    margin-top: 0;
  }

  .seoText1 {
    max-width: 100%;
    margin-bottom: 3rem;
  }

  .seoText2 {
    max-width: 100%;
  }

  .row6Ah {
    grid-row: 5 / 6;
    grid-column: 2 / 17;
    margin-top: 3rem;
  }

  .row6Bh {
    grid-row: 6 / 7;
    grid-column: 3 / 16;
    margin-bottom: 1rem;
    height: 30rem;
  }

  .slideShowPic {
    height: 25rem;
  }

  .slideShowContainer {
    height: 25rem;
  }

  .row6H {
    grid-column: 1 / 18;
    grid-row: 7 / 8;
  }

  .reviewImage {
    max-width: 20rem;
  }

  .row7H {
    grid-row: 8 / 9;
    grid-column: 1 / 18;
    justify-content: space-evenly;
  }

  .row8H {
    grid-row: 9 / 10;
    grid-column: 1 / 18;
  }

  .joiningIt {
    display: grid;
    grid-column: 4 / 15;
    grid-row: 10 / 11;
  }

  .findingIt {
    display: grid;
    grid-column: 4 / 15;
    grid-row: 11 / 12;
  }

  .learningIt {
    display: grid;
    grid-column: 4 / 15;
    grid-row: 12 / 13;
  }

  .designingIt {
    display: grid;
    grid-column: 4 / 15;
    grid-row: 13 / 14;
  }

  .designingItCardText {
    margin-bottom: 5rem;
  }

  .learningItCardText {
    margin-bottom: 6rem;
  }

  .demoPreviewImage4 {
    margin-bottom: 2rem;
  }

  .cardButton1 {
    width: 7rem;
  }

  .cardButton2 {
    width: 7rem;
  }

  .cardButton3 {
    width: 7rem;
  }

  .cardButton4 {
    width: 7rem;
  }

  .courseLink {
    font-size: 1rem;
  }

  .courseLink2 {
    font-size: 1rem;
  }

  .courseLinkDesignIt {
    font-size: 1rem;
  }

  .designingItCardText {
    margin-bottom: 7rem;
  }

  .row11H {
    display: grid;
    grid-row: 14 / 15;
    grid-column: 1 / 18;
    justify-content: space-evenly;
    margin-bottom: 0;
    margin-top: 3rem;
  }

  .row12H {
    display: grid;
    grid-row: 15 / 16;
    grid-column: 1 / 18;
    justify-content: space-evenly;
    margin-bottom: 3rem;
  }

  .why {
    font-size: 0.8rem;
  }

  .review1 {
    margin-left: 0;
  }

  .review3 {
    margin-left: 0;
  }

  .row13Ah {
    grid-column: 2 / 17;
    grid-row: 16 /17;
    margin-bottom: 0;
    margin-top: 2rem;
  }

  .skillsText1 {
    max-width: 90%;
    margin-bottom: 2rem;
  }

  .skillsText2 {
    max-width: 90%;
    margin-bottom: 2rem;
    text-align: center;
  }

  .row13Bh {
    grid-column: 3 / 16;
    grid-row: 17 /18;
    margin-top: 3rem;
  }

  .buttonSpacer2 {
    margin-left: 3rem;
  }
}

//*********************************************************//

@media (max-width: 700px) {
  .hubspotForm {
    width: 20rem;
    height: 60rem;
    margin-top: 2rem !important;
    margin: auto;
    margin-bottom: 15rem;
  }

  .liveStorm {
    margin-bottom: -4rem;
  }

  .row2H {
    margin-bottom: 1rem;
    max-width: 100%;
    padding: 1px;
  }

  .row3H {
    margin-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 1rem;
  }

  .row3Bh {
    margin-top: 0;
    font-size: 1rem;
  }

  .getStartedText {
    font-size: 1rem;
  }

  .getStartedWhite {
    font-size: 1.3rem;
  }

  .getStartedBlue {
    font-size: 1.3rem;
  }

  .seoText1 {
    font-size: 1.7rem;
  }

  .seoText2 {
    font-size: 1rem;
  }

  .row4H {
    grid-column: 1 / 18;
    font-size: 1.7rem;
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .row4Bh {
    font-size: 1rem;
  }

  .row5H {
    display: grid;
    grid-column: 2 / 17;
    margin: auto;
    font-size: 1rem;
  }

  .row6Ah {
    margin-top: 1rem;
  }

  .row6Bh {
    grid-row: 6 / 7;
    grid-column: 3 / 16;
    margin-bottom: 1rem;
    margin-top: 1rem;
    height: 20rem;
  }

  .row7HText {
    font-size: 1.5rem;
  }

  .row8A {
    grid-row: 8 / 9;
    grid-column: 5 / 14;
    // margin: auto;
    margin-bottom: 5rem;
    background-color: white;
  }

  .row8B {
    grid-row: 9 / 10;
    grid-column: 5 / 14;
    margin-bottom: 5rem;
    background-color: white;
  }

  .row8C {
    grid-row: 10 / 11;
    grid-column: 5 / 14;
    margin-bottom: 5rem;
    background-color: white;
  }

  .whyText {
    font-size: 1rem;
  }

  .slideShowPic {
    height: 15rem;
  }

  .slideShowContainer {
    height: 15rem;
  }

  .row5HText {
    font-size: 1.7rem;
  }

  .cardButton1 {
    width: 5rem;
    margin-right: 1rem;
  }

  .cardButton2 {
    width: 5rem;
    margin-right: 1rem;
  }

  .cardButton3 {
    width: 5rem;
    margin-right: 1rem;
  }

  .cardButton4 {
    width: 5rem;
    margin-right: 1rem;
  }

  .courseLink {
    font-size: 0.8rem;
  }

  .courseLink2 {
    font-size: 0.8rem;
  }

  .courseLinkDesignIt {
    font-size: 0.7rem;
  }

  .designingItCardText {
    margin-bottom: 7rem;
  }

  .cardText {
    font-size: 1.2rem;
  }

  .cardText2 {
    font-size: 1rem;
  }

  .joiningIt {
    grid-column: 2 /17;
  }

  .findingIt {
    grid-column: 2 /17;
  }

  .learningIt {
    grid-column: 2 /17;
  }

  .designingIt {
    grid-column: 2 /17;
  }

  .row5H {
    margin-top: 0rem;
  }

  .skillsText1 {
    font-size: 1.7rem;
  }

  .skillsText2 {
    font-size: 1rem;
  }

  .buttonSpacer {
    margin-left: 0rem;
  }

  .buttonSpacer2 {
    margin-left: 2rem;
  }

  .blackBoardContainer {
    margin: 1rem;
  }

  .reviewImage {
    max-width: 20rem;
  }

  .review2 {
    display: none;
  }

  .review4 {
    display: none;
  }

  .row11H {
    justify-content: space-evenly;
    margin-bottom: 0;
    margin-top: 3rem;
    margin-right: 12rem;
  }

  .row12H {
    justify-content: space-evenly;
    margin-bottom: 3rem;
    margin-right: 12rem;
  }

  .getStartedButton {
    width: 7rem;
  }

  .demobutton {
    width: 7rem;
  }

  .getStartedButtonSeo {
    width: 7rem;
  }

  .skillsButtonWrapper {
    margin: auto;
  }
}


