@import "variables";

// .tooltipTriggerContentWrapper,
// .tooltipTriggerContentWrapper>div[role=presentation] {
//   display: inline;
// }


// .tooltip .tooltip-inner::before,
.tooltipProTip::before {
  content: "";
  display: block;
  background-image: url("../images/light-bulb-icon.png");
  width: 20px;
  height: 20px;
  float: left;
}

// #button-tooltip {
//   opacity: 1;
// }

a .atlas-tooltip-inner {
  cursor: pointer;
}