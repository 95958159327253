@import "variables";

// Is this necessary? This seems like it should be imported in style.scss
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");


.planWrapper {
  display: grid;
  background-color: white;
  grid-template-columns: 0.5fr repeat(6, 1fr) 0.5fr;
  width: 100%;
}

.pricingHeader {
  display: grid;
  grid-column: 2 / 8;
  grid-row: 1 / 1;
  font-kerning: normal;
  font-size: 2.75rem;
  background-image: $FFgrad1;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-top: 90px;
  margin-bottom: 10px;
  border-top-right-radius: 25px;
  position: relative;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.pricingRow {
  border-right: solid black 1px;
  text-align: center;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresRowP {
  font-size: 1.1rem;
}

.row2P {
  grid-row: 2 / 3;
}

.row3P {
  grid-row: 3 / 4;
}

.row4P {
  grid-row: 4 / 5;
  display: grid;
}

.row5P {
  grid-row: 5 / 6;
}

.row6P {
  grid-row: 6 / 7;
}

.row7P {
  grid-row: 7 / 8;
}

.row8P {
  grid-row: 8 / 9;
}

.row9P {
  grid-row: 9 / 10;
}

.row10P {
  grid-row: 10 / 11;
}

.row11P {
  grid-row: 11 / 12;
}

.row12P {
  grid-row: 12 / 13;
}

.row13P {
  grid-row: 13 / 14;
}

.row14P {
  grid-row: 14 / 15;
}

.row15P {
  grid-row: 15 / 16;
}

.row16P {
  grid-row: 16 / 17;
}

.row17P {
  grid-row: 17 / 18;
}

.row18P {
  grid-row: 18 / 19;
}

.row19P {
  grid-row: 19 / 20;
}

.row20P {
  grid-row: 20 / 21;
}

.row21P {
  grid-row: 21 / 22;
}

.row22P {
  grid-row: 22 / 23;
}

.row23P {
  grid-row: 23 / 24;
}

.row24P {
  grid-row: 24 / 25;
}

.bottomRow {
  margin-bottom: 3rem;
}

.column2 {
  grid-column: 2 / 3;
}

.column3 {
  grid-column: 3 / 4;
}

.column4 {
  grid-column: 4 / 5;
}

.column5 {
  grid-column: 5 / 6;
}

.column6 {
  grid-column: 6 / 7;
}

.column7 {
  grid-column: 7 / 8;
  border: none;
}

.choosePlanCtaBtn {
  font-size: 0.8rem;
  padding: 5px;
  background-color: #582b7d;
  border: solid #b07ddd 6px;
}

.choosePlanContact {
  font-size: 0.8rem;
  padding: 5px;
  background-color: #582b7d;
  border: solid #b07ddd 6px;
}

//.freeTrial-button {
// //   margin-top: 3rem;
// }

.alternateRowColor {
  background-color: #9fd4f0;
}

@media (max-width: 810px) {
.choosePlanCtaBtn {
    font-size: 0.7rem;
  }

.choosePlanContact {
    font-size: 0.7rem;
  }

 .pricingRow {
    font-size: 0.625rem;
  }

 .featuresRowP {
    font-size: 0.9rem;
  }
}

@media (max-width: 700px) {

}
