@import "variables";


.form-check {
  margin-bottom: 10px;
  padding-top: .375rem;
}

// .form-check-input {
//   margin-top: 0;
// }

// // Stackoverflow suggestion
// // .modal-content {
// //   background-color: $FFltbeige;
// // }

// .modal-header {
//   font-weight: bold;
//   color: white;
//   background-color: $FFblu2;
// }

// .modal-title {
//   font-weight: bold;
// }

// .assumptions {
//   font-family: $open-sans;
//   color: $FFblu2;
// }

// .assumptionsTitle {
//   font-size: 1rem;
// }

// .modal-body {
//   padding: 1.3rem;
// }

// .joinItResultType {
//   font-size: 120%;
//   padding-bottom: 0;
// }

// .assumptionsBody {
//   background-color: $FFdkgrey;
//   color: white;
//   border-radius: 25px;
//   margin: 0 auto;
//   margin-bottom: 1.3rem;
//   width: 90%;
//   font-size: 70%;
// }

// .reviewResponses>div:nth-child(1) {
//   background-color: $FFdkbeige;
//   border-radius: 15px 15px 0px 0px;
//   font-size: 0.9rem;
//   font-weight: bold;
//   padding: 10px;
//   color: $FFdkdkbeige;
// }

// .reviewResponses>div:nth-child(2) {
//   padding: 10px;
//   color: $FFdkgrey;
// }

// .assumptionsBody>h3 {
//   font-weight: bold;
// }

// .questionresponse>h4 {
//   font-weight: bold;
//   font-size: 0.9rem;
//   border-bottom: 1px solid $FFdkbeige;
// }

// .questionresponse {
//   font-size: 0.9rem;
// }

.jointFinderContainer {
  color: #052c45;
  margin-top: 3rem;
  padding-top: 2rem;
  width: 960px;
  margin: auto;
}

.joiningItHeader {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-left: 80px;
  margin-right: 80px;
}

.exclusionsHeader {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.checklistText p {
  color: #0a6b7c;
}

.checklistButton {
  width: 100%;
  color: #052c45;
  background-color: white;
  border: none;
  margin: auto;
  margin-bottom: 10px;
  padding: 5px;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  font-size: 1.1rem;
}

.multipleSelectionItem {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
}



.multipleSelectionItem label {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  color: #0a6b7c;
}

.jfqHeader {
  color: #052c45;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.btnPrimary {
  background-color: #3c649b;
}

.joinItNext {
  float: right;
  background-color: #3c649b;
}

.joinItNodeText {
  border-bottom: 1px solid #d6dbc2;
  margin-bottom: 1rem;
}

.modalHeader {
  font-weight: bold;
  color: white;
  background-color: $FFblu2;
}

.cardBody {
  padding: 1.25rem 0 0 0;
}

.joinItUnitSystemContainer .row {
  justify-content: center;
}

.materialLayer {
  grid-template: 1fr, 1fr, 1fr, 1fr;
  display: grid;
  grid-auto-flow: column;
  font-size: 77%;
  font-weight: bold;
  margin: 15px 0px 15px 0px;
  justify-content: center;
}

.materialLayerInvalid {
  border: 2px solid red;
}

.materialLayerRows1 {
  display: grid;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  margin-right: 1rem;
}

.materialLayerRowsSelect {
  width: 10rem;
}

.materialQuestionInvalidResponseNag {
  display: block;
  color: $FFred;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}

.msquestionUnsavedChangesNag {
  display: block;
  color: $FFred;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}

//.cssTlfecz-indicatorContainer {
//   padding: 5px !important;
// }

.materialLayerRows2 {
  display: grid;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  margin-right: 1rem;
}

.materialLayerRows3 {
  display: grid;
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  margin-right: 1rem;
}

.materialLayerRows4 {
  display: grid;
  grid-row: 1 / 2;
  grid-column: 4 / 5;
}

.materialLayerContainer hr {
  border-top: 1px solid #3c649b;
  width: 100%;
}

.topbread {
  background-color: #3c649b;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 5px 0px 0px 25px;
  font-size: 90%;
  font-weight: bold;
  color: white;
}

.bottombread {
  background-color: #3c649b;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  padding: 5px 0px 0px 25px;
  font-size: 90%;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
  margin-top: 5px;
}

.joiningItHolesTitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 0.5rem;
}

.joiningItHolesDetail {
  font-size: 1.1rem;
}

.unitSystemSelectJoiningIt {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: baseline;
  justify-items: center;
  padding-top: 2rem;
}

.unitSystemLabelJoiningIt {
  grid-column: 6 / 8;
  margin-right: 1rem;
}

.toggleJoiningItWrapper {
  display: inline-flex;
  flex-direction: row;
  grid-column: 8 / 9;
}

.joiningItImperialText {
  padding-right: 1rem;
}

.joiningItMetricText {
  padding-left: 1rem;
}

.materialQuestionJoiningItButton {
  float: right;
}

.mcquestionOption {
  display: flex;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr ;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 2rem;
}

.mcquestionOptionActive {
  max-width: 58rem;
  border-radius: .25rem;
  padding: .5rem;
  background-color: #f0f1eb;
  border: solid 2px #8d8f88;
}

.joiningItTooltipText {
  font-size: 1rem;
  color: black;
}

.joiningItTooltipTextLink {
  font-size: 1rem;
  color: black;
}

.joiningItTooltipTextLink:hover,
.joiningItTooltipTextLink,
a:hover {
  font-size: 1rem;
  color: blue;
  text-decoration: none;
}

.joiningItTooltipTitle {
  font-size: 1.2rem;
}

.joiningItTooltipImage {
  width: 300px;
}

.msQuestionFooterWrapper {
  display: flex;
  float: right;
}

.joiningItHolesTitle {
  margin: auto;
}

.joiningItHolesTooltip {
  margin: auto;
  padding-left: 1rem;
  font-size: 1.5rem;
  margin-left: 0;
}

.msQuestionFooterText {
  margin-top: 2rem;
  font-size: 1rem;
  margin-right: 2rem;
}


.msQuestionOptionGroupContainer:not(:first-child) {
  margin-top: 2rem;
}

.msQuestionOptionGroupOptionsContainer {
  border: 2px solid #0a6b7c;
  border-radius: 0.25rem;
}

.msQuestionOptionGroupTitle {
  font-size: 1.5rem;
}

.multipleSelectionItem>.formCheck {
  display: inline-block;
}

.multipleSelectionItemIcon {
  height: 5rem;
  padding-right: 1rem;
  padding-left: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

$jfwidth: 50rem;

.joinIt {
  width: $jfwidth;
  margin-left: auto;
  margin-right: auto;
}

.jointFinderContainer {
  margin: auto;
  width: 960px;
  padding-top: 140px;
}

.jointFinderQuestion {
  padding: 30px 40px 30px 40px;
  // border-radius: 25px;
}

.jfqHeader {
  font-size: 150%;
  font-weight: bold;
  color: $FFblu2;
}

.formEntryStandard {
  width: 147px;
  height: 38px;
  border-style: solid;
  border-color: $FFdkbeige;
  border-radius: 4px;
  border-width: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.checkboxEmphasized {
  font-weight: bold;
  color: $FFred;
}

.alertDanger {
  background-color: #eec4c2;
  padding: 15px 15px -5px -5px;
  font-style: normal; //@Kai @Evan I can't figure out why this is appearing in italics? Seems like it's something to do w/React markdown
  border-radius: 5px;
  border: none;
  font-size: 0.85rem;
}

.alertInfo {
  background-color: $FFdkbeige;
  border-radius: 5px;
  border: none;
  font-size: 0.85rem;
  color: $FFdkdkbeige;
}

// @Kai / @Evan: I can't seem to get the bottom padding in here to go away
.alertDanger>div>p {
  margin-left: 0;
  margin-bottom: -10px;
}

.formCheck {
  margin-bottom: 10px;
  padding-top: .375rem;
}

.formCheckInput {
  margin-top: 0;
}

// Stackoverflow suggestion
.modalContent {
  background-color: $FFltbeige;
}

.modalHeader {
  font-weight: bold;
  color: white;
  background-color: $FFblu2;
}

.modalTitle {
  font-weight: bold;
}

.assumptions {
  font-family: $open-sans;
  color: $FFblu2;
}

.assumptionsTitle {
  font-size: 1rem;
}

.modalBody {
  padding: 1.3rem;
}

.joinItResultType {
  font-size: 120%;
  padding-bottom: 0;
}

.assumptionsBody {
  background-color: $FFdkgrey;
  color: white;
  border-radius: 25px;
  margin: 0 auto;
  margin-bottom: 1.3rem;
  width: 90%;
  font-size: 70%;
}

.reviewResponses>div:nth-child(1) {
  background-color: $FFdkbeige;
  border-radius: 15px 15px 0px 0px;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 10px;
  color: $FFdkdkbeige;
}

.reviewResponses>div:nth-child(2) {
  padding: 10px;
  color: $FFdkgrey;
}

.assumptionsBody>h3 {
  font-weight: bold;
}

.questionresponse>h4 {
  font-weight: bold;
  font-size: 0.9rem;
  border-bottom: 1px solid $FFdkbeige;
}

.questionresponse {
  font-size: 0.9rem;
}

//mobileview//

@media (max-width: 600px) {
  .jointFinderContainer {
    max-width: 90%;
    color: #052c45;
    margin-top: 4rem;
    padding-top: 2rem;
  }

  // .jointFinderContainer {
  //   max-width: 90%;
  //   color: #052c45;
  //   padding-top: 2rem;
  // }

 .joiningItHeader {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-left: 0;
    margin-right: 0;
  }

  .joinIt {
    width: auto;
  }

.multipleSelectionItem label {
    cursor: pointer;
    font-size: calc(min(1.2rem, 3.3vw));
    font-weight: bold;
    color: #0a6b7c;
  }

.multipleSelectionItem {
    font-size: 0.5rem;
    margin: auto;
  }

.multipleSelectionItemIcon {
    padding-left: 0;
    grid-column: 1 / 2;
  }

 .formLabel {
    text-align: left;
    margin-top: 0.5rem;
  }

  .materialLayer {
    grid-template-columns: 1fr, 1fr, 1fr, 1fr;
    display: grid;
    font-size: 77%;
    font-weight: bold;
    margin: 15px 0px 15px 0px;
  }

.materialLayerRows1 {
    display: grid;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

.materialLayerRows2 {
    display: grid;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

.materialLayerRows3 {
    display: grid;
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

.materialLayerRows4 {
    display: grid;
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

 .ffButton.med {
    font-size: calc(min(1.2rem, 3.3vw));
  }

.unitSystemLabel {
    grid-column: 1 / 4;
  }

  .unitSystemSelectJoiningIt {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    align-items: baseline;
    justify-items: center;
    align-items: center;
    padding-top: 2rem;
    padding-left: 2.5rem;
    margin: auto;
  }

.joiningItHolesTitle {
    // display: inline-block;
    grid-column: 2 / 3;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0.5rem;
    // margin-bottom: 0;
  }

 .joiningItHoles- {
    grid-column: 3 / 4;
    font-size: 0.8rem;
    max-width: 10rem;
  }

 .mcquestionOption {
    display: inline-block;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 2rem;
  }

.mcquestionOptionActive {
    max-width: 18rem;
    border-radius: .25rem;
    padding: .5rem;
    background-color: #f0f1eb;
    border: solid 2px #8d8f88;
  }
}