// Bootstrap fonts
$font-family-sans-serif: Verdana, -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

// typefaces
$staatliches: "Staatliches", sans-serif;
$oswald: "Oswald", "sans-serif";
$happy-monkey: "Happy Monkey", cursive;
$over-the-rainbow: "Over the Rainbow", sans-serif;
$open-sans: "Open Sans", sans-serif;
$body-font: $open-sans;
$header-font: $staatliches;

// colors
$FFblu0: #061f29;
$FFblu1: #163e4e;
$FFblu2: #327c9b;
$FFblu3: #48c0ea;
$FFdkdkbeige: #656954;
$FFdkbeige: #d6dbc2;
$FFltbeige: #eef1e2;
$FFltgrey: #dfe2ea;
$FFmdgrey: #b1b5be;
$FFdkgrey: #707070;
$FFred: #8c221b;

// new colors
$FFmidNightBlue: #052c45;
$FFdarkSlate: #582b7d;
$FFsteelBlue: #3c649b;
$FFturquoise: #17e7d9;
$FFturqoise2: #1dc5d9;
$FFmedTurquoise: #3daee2;
$FFlightTurquoise: #62d9d9;
$FFteal: #0a6b76;

// gradients
$FFgrad1: linear-gradient(to bottom right, #266783, #3aa2c7);
$FFgrad2: linear-gradient(to bottom, white, #ffffff00);
$FFgrad3: linear-gradient(to bottom, #3199be, #3199be00);
$FFgrad4: linear-gradient(to right, #591611, $FFred);
$FFgrad5: linear-gradient(to bottom right, #18516b, #2686a8);

$FFfindItHeaderGrad: linear-gradient(to right, #0a6b76, #0a6b76);

$FFrainbow: linear-gradient(to right,
  #052c45,
  #582b7d,
  #3c649b,
  #17e7d9,
  #1dc5d9,
  #3daee2,
  #62d9d9,
  #0a6b7c);

$FFrainbowGrad: linear-gradient(to right,
  #052c45,
  #582b7d,
  #3c649b,
  #3c649b,
  #3daee2,
  #3daee2,
  #1dc5d9,
  #17e7d9,
  #62d9d9);

// Other formatting details
$enable-shadows: true;