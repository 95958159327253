.ffButton {
  cursor: pointer;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-size: 1.45em;
  color: white;
  background-color: #0a6b7c;
  border: solid #069aab 6px;
  border-radius: 0.8rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0.5em;
}

.ffButton:not(:disabled) {
  cursor: pointer;
}

.ffButton:hover, .ff-button:focus {
  transform: scale(1.05);
}

.ffButton.sm {
  font-size: 0.8em;
}

.ffButton.med {
  font-size: 1.0em;
}