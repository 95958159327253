@import "variables";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

body {
  font: 100% $body-font;
  background-color: $FFblu1;
}

iframe {
  display: block;
  border-style: none;
  margin: 0 auto;
}

#hubspotForm {
  width: 40rem;
  height: 50rem;
  margin-top: 2rem !important;
  margin: auto;
  margin-bottom: 15rem;
}