//ArticlesLandingPage//

.articleWrapper {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 3rem;
  margin-bottom: 5rem;
  margin: auto;
  margin-top: 4rem;
}

.articleHeader1 {
  display: grid;
  grid-column: 1 / 1;
  color: white;
  text-align: center;
}

.articleHeader2 {
  display: grid;
  grid-column: 2 / 3;
  color: white;
  text-align: center;
}

.articleItemWrapper {
  display: grid;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  text-align: center;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
}

.articleItemText {
  display: block;
  color: white;
  font-weight: bold;
  margin-top: 1rem;
  max-width: 30ch;
}

.articleHeader {
  width: 90%;
  display: grid;
  grid-column: 1 / -1;
  margin: auto;
  margin-bottom: 2rem;
}

.articleImage {
  border-radius: 10px;
  width: 400px;
  height: 350px;
}

//Articles//

.articlesWrapper {
  margin: auto;
}

.articlesMain {
  position: relative;
  margin: auto;
  width: 60%;
  height: auto;
  background-color: white;
  text-align: center;
  padding: 5rem;
}

.articlesImage {
  width: 70%;
  border: black 2px solid;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.articlesHeader {
  margin-top: 1rem;
  font-size: 2rem;
}

.articlesText {
  padding: 0.5rem;
  text-align: left;
}

//Mobile View//

@media (max-width: 700px) {
  .articleWrapper {
    max-width: 70%;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 3rem;
    margin-bottom: 5rem;
    margin: auto;
    margin-top: 4rem;
  }

  .articleHeader1 {
    display: grid;
    // grid-column: 1 / 1;
    color: white;
    text-align: center;
    display: none;
  }

  .articleHeader2 {
    display: grid;
    // grid-column: 2 / 3;
    color: white;
    text-align: center;
    display: none;
  }

  .articleItemWrapper {
    display: grid;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
    text-align: center;
    grid-auto-flow: row;
    justify-items: center;
    align-items: center;
  }

  .articleImage {
    border-radius: 10px;
    width: 200px;
    height: 175px;
    margin: auto;
  }

  .articleHeader {
    width: 100%;
  }

  //Articles//

  .articlesWrapper {
    margin: auto;
  }

  .articlesMain {
    position: relative;
    margin: auto;
    width: 90%;
    height: auto;
    background-color: white;
    text-align: center;
    // padding: 3rem;
  }

  .articlesImage {
    width: 100%;
    border: black 2px solid;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .articlesHeader {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .articlesText {
    padding: 0.5rem;
    text-align: left;
    font-size: .7rem;
  }
}
