

.footerContainer {
    grid-template-columns: repeat(10, 1fr);
    display: grid;
    width: 100%;
    position: relative;
    min-height: 3rem;
    margin: auto;
    align-items: center;
}

.copyright {
    grid-row: 1 / 2;
    grid-column: 2 / 5;
    color: white;
    font-size: .75rem;
}

.footerLink1 {
    grid-row: 1 / 2;
    grid-column: 5 / 6;
    color: white;
}

.footerLink2 {
    grid-row: 1 / 2;
    grid-column: 6 / 7;
    color: white;
}

.footerLink3 {
    grid-row: 1 / 2;
    grid-column: 7 / 8;
    color: white;
}

.footerLink4 {
    grid-row: 1 / 2;
    grid-column: 8 / 9;
    color: white;
}

.linkedInFooter {
    width: 1.8rem;
    grid-row: 1 / 2;
    grid-column: 9 / 10;
    margin-left: 2rem;
}

//********************************************************//

@media (max-width: 1400px) {

.copyright {
    grid-row: 1 / 2;
    grid-column: 2 / 5;
    color: white;
    margin-bottom: 1rem;
}

.footerLink1 {
    grid-row: 1 / 2;
    grid-column: 7 / 8;
    color: white;
    margin-bottom: 1rem;
}

.footerLink2 {
    grid-row: 2 / 3;
    grid-column: 2 / 5;
    color: white;
}

.footerLink3 {
    grid-row: 2 / 3;
    grid-column: 7 / 8;
    color: white;
}

.footerLink4 {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    color: white;
    margin-top: 1rem;
    font-size: .75rem;
}

}




