@import "variables";

.accountCreationPageContainer {
  width: 90%;
  min-width: 20rem;
  max-width: 40rem;
  margin: auto;
  margin-top: 5rem;
}

.termsAndConditionsScrollContainer {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 20rem;
  border: 1px solid white;
  padding: 1rem;
  border-radius: 0.5rem;
  color: black;
  background-color: white;
}

.stripeCardElementWrapper {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: white;
}

.accountCreationForm #cardErrors {
  color: red
}

.accountCreationForm {
  color: white;
  width: 100%;
}

.fieldContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.accountCreationCityStateZipContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 2rem;
}

.accountCreationCityStateZipContainer .fieldContainer {
  display: inline-block;
}

.accountCreationForm label {
  display: block;
  font-style: italic;
}

.requiredAsterisk {
  color: red;
}

.accountCreationForm input,
.accountCreationForm textarea,
.accountCreationForm select {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 1rem;
}

.accountCreationForm textarea {
  height: 5rem;
}

.checkboxFieldContainer input {
  display: inline;
  width: 1.5rem;
  height: 1.5rem;
}

.checkboxFieldContainer label {
  display: inline;
  font-weight: bold;
}

.accountCreationFormFieldErrorMessage {
  color: red;
}

.logo {
  width: 100%
}

.accountCreationSubmitButton {
  display: block;
}

.accountCreationForm .hiddenEmailField {
  display: none;
}

.verify {
  width: 37rem;
  background-color: $FFblu0;
  color: white;
  font-family: $open-sans;
  padding: 40px;
  margin: auto;
  font-size: 120%;
  position: relative;
  border-radius: 25px;
  margin-bottom: 3rem;
}

.verify>h2 {
  font-weight: bold;
}

.verify>.btn {
  position: absolute;
  right: 40px;
  bottom: -1.4rem;
}

.onboard {
  width: 37rem;
  background-color: $FFltbeige;
  color: $FFblu1;
  font-family: $open-sans;
  padding: 40px;
  margin-top: 350px;
  margin: auto;
  font-size: 120%;
  // font-weight: bold;
  position: relative;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.3);
  position: relative;
}

.onboard>h2 {
  background-color: $FFred;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  top: -1.3rem;
  margin-bottom: 1.3rem;
  font-size: 1.3rem;
  padding: 0.7rem 1.7rem 0.7rem 1.7rem;
}