$FFrainbow: linear-gradient(
  to right,
  #052c45,
  #052c45,
  #582b7d,
  #582b7d,
  #0a6b7c,
  #3c649b,
  #3c649b
);

$navbarHeight: 82px;
$logoWidth: 315px;

.navLogo {
  cursor: pointer;
  height: $navbarHeight;
}

.navContainer {
  position: fixed;
  top: 0;
  display: grid;
  grid-template-columns: $logoWidth auto auto 0;
  align-items: center;
  z-index: 999;
  background-image: $FFrainbow;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: $navbarHeight;
  width: 100%;
}

.navButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.navMenuList > .navButtonContainer {
  display: none;
}

.navButtonContainer button {
  margin: 0 0.5rem 0 0.5rem;
}

$hamburgerSize: 3rem;
.hamburger {
  border: 2px solid rgb(146, 100, 183);
  height: $hamburgerSize;
  width: $hamburgerSize;
  padding: 0.5rem;
  border-radius: 10%;
  background-color: rgb(76, 37, 108);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.navMenu {
  display: flex;
  justify-content: left;
  margin-left: 2rem;
  white-space: nowrap;
  font-size: 0.8rem;
}

.navMenu ul {
  display: flex;
  // padding-left: 1rem;
}

.navMenu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navMenu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.navMenuSublistContainer {
  display: none;
  position: absolute;
  z-index: 1;
  margin-left: 0.3rem;
  background-color: #052c45;
  text-decoration: none;
  color: black;
  border-radius: 0.5rem;
  border: solid white 1px;
}

.dropdownMenu:hover .navMenuSublistContainer {
  display: block;
  background-color: #052c45;
}

.dropdownMenu:hover .navMenuSublist {
  display: flex;
  flex-direction: column;
}

.dropdownMenu h3 > img {
  margin-left: 0.25rem;
  width: 1rem;
}

.navMenuList h3 {
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding-top: 1rem;
  padding-right: 2.5rem;
}

.navMenuSublist h3 {
  font-size: 1rem;
  padding: 0.7rem;
}

.linksHidden :hover {
  font-size: 1.3rem;
  text-decoration: underline !important;
}

.navMenuSublistContainer a:hover {
  text-decoration: none;
}

.navMenuListUnderlay {
  z-index: 2;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
}

@media screen and (max-width: 1425px) {
  .navMenuList h3 {
    color: white;
    font-size: 1rem;
    cursor: pointer;
    padding-top: 1rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 1305px) {
  .navMenuList h3 {
    font-size: 1rem;
  }

  .navMenuSublist h3 {
    font-size: 1.3rem;
  }
}

// @media screen and (max-width: 1130px) {
//   .navMenuList h3 {
//     font-size: 1rem;
//   }

//   .navMenuSublist h3 {
//     font-size: 1.3rem;
//   }
// }

// Hide menu by default, display upon clicking hamburger button
@media screen and (max-width: 1280px) {
  .navContainer {
    grid-template-columns: auto 0 auto calc(#{$navbarHeight} + 1rem);
  }

  .navContainer > .navButtonContainer {
    display: none;
  }

  .navMenuList > .navButtonContainer {
    display: block;
    margin-top: 0.5rem;
  }

  .navMenuList h3 {
    color: white;
    font-size: 1rem;
    cursor: pointer;
    padding-top: 1rem;
  }

  .navMenuSublist h3 {
    font-size: 0.75rem;
    padding: 0.25rem;
  }

  .navMenuSublist h3:hover {
    font-size: 0.75rem;
    padding: 0.25rem;
  }

  .hamburger {
    display: block;
  }

  .navMenu ul {
    flex-direction: column;
    position: absolute;
    top: $navbarHeight;
    right: 0;
    background-color: white;
    border-top: 1px solid white;
    display: none;
  }

  .navMenuList {
    border: white 3px solid;
  }

  .navMenu.navMenuExpanded ul {
    display: block;
    background-color: #052c45;
  }

  .navMenu.navMenuExpanded ul li {
    display: block;
    background-color: #052c45;
  }

  .navMenuSublistContainer ul {
    top: 0;
    position: relative;
  }

  .navMenuSublistContainer {
    display: block;
    position: unset;
    z-index: unset;
    margin-left: auto;
    border: none;
  }

  .linksHidden {
    font-size: 0.1rem;
  }

  // .dropdownMenu {
  //   font-size: .3rem;
  // }
}

@media screen and (max-width: 500px) {
  .navMenuExpanded {
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100vh; //todo: delete
  }

  .navMenuExpanded ul {
    width: 100vw;
  }

  .navMenuListRoot {
    max-height: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .navMenuList h3 {
    font-size: 6.5vw;
  }

  .navMenuSublist h3 {
    font-size: 5.5vw;
  }

  .navMenuList h3:hover {
    font-size: 6.5vw;
  }

  .navMenuSublist h3:hover {
    font-size: 5.5vw;
  }
  //todo: scale font sizes based on width
}

/* -------------------------------------------- */

.missionStatementHeader {
  color: white;
  text-align: center;
  margin-top: 6rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2.5rem;
  background-color: teal;
  margin-left: 25rem;
  margin-right: 25rem;
  border-radius: 1rem;
}

.missionStatementContent {
  color: white;
  margin-top: 4rem;
  margin-left: 25rem;
  margin-right: 25rem;
  font-size: 1.8rem;
  margin-bottom: 17rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.problemHeader {
  color: white;
  text-align: center;
  margin-top: 6rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2.5rem;
  background-color: teal;
  margin-left: 25rem;
  margin-right: 25rem;
  border-radius: 1rem;
}

.solutionHeader {
  color: white;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2.5rem;
  background-color: #582b7d;
  margin-left: 25rem;
  margin-right: 25rem;
  border-radius: 1rem;
}

.problemSolutionContent {
  color: white;
  margin-left: 25rem;
  margin-right: 25rem;
  font-size: 1.3rem;
  margin-bottom: 10rem;
  margin-top: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
