@import "variables";

.aboutUsWrapper {
  width: 80%;
  background-color: white;
  margin: auto;
}

.aboutUsHeader {
  grid-column: 1 / 4;
  width: 100%;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  font-family: $open-sans;
  margin: auto;
  margin-top: 7rem;
  margin-bottom: 3rem;
  background-color: #0a6b7c;
  text-align: center;
}

.aboutUsItemWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.aboutUsColumn1 {
  display: grid;
  grid-column: 1 / 2;
  margin: auto;
  text-align: center;
}

.aboutUsItemText {
  display: grid;
  grid-column: 2 / 4;
  margin: auto;
}

.aboutUsItemMember {
  color: #582b7d;
  font-weight: bold;
  font-family: $over-the-rainbow;
  font-size: 2rem;
}

.aboutUsItemTitle {
  color: #582b7d;
  margin-bottom: 2rem;
}

.aboutUsSpacer {
  margin-bottom: 5rem;
}

.linkedIn {
  width: 1.5rem;
  margin-left: 1rem;
}

@media (max-width: 1250px) {
  .aboutUsColumn1 {
    display: grid;
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    margin: auto;
    text-align: center;
  }

  .aboutUsItemText {
    display: grid;
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    margin: auto;
  }
}

@media (max-width: 700px) {
  .aboutUsItemPic {
    max-width: 15rem;
  }
}
