.infoIcon {
  height: 1em;
  margin-top: -0.2em;
}

.hidden {
  visibility: hidden;
}

.requiredFieldIndicator {
  color: red;
}