.introCoursesWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 7rem;
  margin-bottom: 5rem;
}

.introCoursesHeader {
  display: grid;
  grid-column: 1 / -1;
  color: white;
  text-align: center;
  margin-bottom: 2.5rem;
}

.introCourseStatement {
  display: grid;
  grid-column: 1 / -1;
  color: white;
  text-align: center;
  margin-bottom: 2.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.courseItemWrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;
  width: 15rem;
  height: 15rem;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  background-color: white;
  border: 4px solid #1dc5d9;
  border-radius: 50%;
}

.courseItemText {
  display: block;
  color: black;
  font-weight: bold;
}

.learningItHeaderCourses {
  width: 90%;
  display: grid;
  grid-column: 1 / -1;
  margin: auto;
  margin-bottom: 2rem;
}

//**************************************************//

@media (max-width: 810px) {
  .introCoursesWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

//**************************************************//

@media (max-width: 600px) {
  .introCoursesWrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
}
