@import "variables";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

// Global formatting
body {
  font: 100% $body-font;
  background-color: $FFblu1;
}

.LoadingScreenWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.LoadingScreenText {
  width: 100%;
  position: absolute;
  top: 0;
  color: white;
  font-size: 2rem;
  white-space: nowrap;
  margin: auto;
  margin-top: 20rem;
  margin-bottom: 2rem;
  text-align: center;
}

.LoadingScreen {
  border: 16px solid teal;
  margin: auto;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.smallH2D {
  font-size: 1rem;
  padding-left: 40px;
}

// ************
// Site-wide styling
// ************

.btn {
  border-radius: 25px;
  font-weight: bold;
  margin: 5px;
}

.btnPrimary {
  color: #fff;
  background-color: $FFblu2;
  border-color: $FFblu2;
}

.btnSecondary {
  font-family: $open-sans;
  background-color: $FFred;
  border: none;
}

.btn:hover {
  background-color: $FFblu3;
  border-color: $FFblu3;
}

.btn:focus {
  background-color: $FFred;
}

.bgImage {
  width: 100%;
}

.bgDark {
  background-color: $FFblu0 !important;
}

.italic {
  font-style: italic;
}

.navbar {
  flex: 0 0 auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  background-image: $FFrainbowGrad;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0px;
  height: 5rem;
}

.signUpTab {
  background-color: #582b7d;
  width: 9rem;
  margin-right: 2rem;
  margin-top: -1rem;
  height: 5rem;
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  text-align: center;
  position: absolute;
  right: 5rem;
  padding-top: 1.75rem;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}

.devbar {
  background-color: $FFred;
  width: 100%;
  position: fixed;
  margin-top: -70px;
  font-size: 10pt;
  font-weight: bold;
  font-family: $open-sans;
  color: white;
  height: 20px;
  z-index: 9;
}

.navbarBrand {
  font-family: $header-font;
}

.redbutton {
  background-color: $FFred;
}

.whitebutton {
  background-color: white;
  color: $FFblu2;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 15px;
}

.homelayout {
  margin: 0;
  padding: 0;
}

.pageHeader {
  font-family: $header-font;
  font-kerning: normal;
  font-size: 2.75rem;
  background-image: $FFgrad1;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top-right-radius: 25px;
}

.baseContainer {
  margin: auto;
  margin-top: 180px;
}

.question {
  background-color: $FFltbeige;
  margin-bottom: 10px;
}

.cardHeader {
  font-size: 110%;
  font-weight: bold;
  background-color: $FFdkbeige;
}

.checkbox {
  color: black;
}

.multipleSelectionDetail p {
  margin-left: 1.4rem;
  color: $FFdkgrey;
  margin-bottom: 0;
  padding-bottom: 1rem;
  font-size: 0.9rem;
}

.checkboxEmphasized {
  font-weight: bold;
  color: $FFred;
}

.navbarSpacer {
  height: 11vh;
}

.hero {
  min-height: 38rem;
  padding-left: 15px;
  margin-left: 15vw;
  display: grid;
  font-size: 4rem;
  font-family: $oswald;
  font-weight: 500;
  color: white;
  text-shadow: 0px 2px 4px #00000060;
}

.sidekick {
  margin-top: 7rem;
}

.sidekick>div {
  margin-top: -0.3em;
  margin-bottom: -0.3em;
}

.heroSubtitle {
  font-size: 1.3rem;
  font-family: $open-sans;
  color: white;
  padding-top: 1.5rem;
  width: 20rem;
}

.heroSubtext {
  font-size: 1rem;
  font-family: $open-sans;
  color: white;
  background-color: $FFred;
  padding: 15px;
  width: 20rem;
}

#beigepart {
  background-color: $FFltbeige;
  min-height: 75rem;
  position: relative;
  max-width: unset;
}

iframe {
  display: block;
  border-style: none;
  margin: 0 auto;
}

#landingcardrow {
  position: relative;
  top: -80px;
  height: 300px;
  width: 80em;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  color: $FFblu1;
  font-size: 10pt;
  grid-template-columns: 25% 25% 25% 25%;
  grid-column-gap: 5px;
  padding-left: -15px;
}

#landingcardrow>div:nth-child(even) {
  background-image: $FFgrad5;
}

.howItWorks {
  margin-top: -55px;
  background-image: $FFgrad2;
  border-radius: 20px;
  color: black;
  font-family: $open-sans;
  font-size: 20px;
  padding-top: 75px;
  text-align: center;
  min-height: 15rem;
}

.howItWorks>div {
  background-color: $FFblu1;
  border-radius: 2.5rem;
  padding: 5.5rem;
  margin-top: 3.5rem;
}

#joinToday>.text {
  text-align: center;
}

#joinToday>.subtext {
  font-family: $open-sans;
  font-size: 0.3em;
  text-align: center;
}

#joinToday>.btn {
  margin-top: 20px;
  padding: 10px 15px 10px 15px;
}

.landingpageBG {
  background-image: url("../images/home-bridge-01.jpg");
  background-color: rgba(0, 0, 0, 0.3);
  max-width: 100%;
  height: 38rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.landingpageCard {
  background-image: $FFgrad1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  width: 16rem;
  height: 17rem;
  border-radius: 0px 30px 0px 0px;
  padding: 30px;
  font-size: 12pt;
  color: white;
  position: relative;
}

.landingpageCardTitle {
  font-family: $oswald;
  font-weight: 500;
  font-size: 2.5rem;
  padding-bottom: 1rem;
  position: relative;
}

.landingpageCardTitle::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 30px;
}

.landingPageCardButton {
  background-color: white;
  color: $FFblu2;
  font-size: 12pt;
  font-weight: bold;
  position: absolute;
  bottom: -1.3rem;
  right: 1.5rem;
}

.landingpageLayout {
  background-color: $FFltbeige !important;
  width: 100%;
}



.whiteGhost {
  background-image: $FFgrad2;
  border-radius: 20px;
  height: 40%;
}

.whiteGhost>.btn {
  position: absolute;
  bottom: 10px;
  width: 10em;
  margin-left: 1.3em;
  background-color: $FFred;
  border: none;
}

// *************
// JointFinder
// *************

//Todo: fix bouncy breakpoint thing - window resize results in unpleasant shifting





.TESTING {
  display: none;
}



.findItFieldRivetSubtype {
  width: 18rem;
}


.findItFieldRivetDiameter {
  position: absolute;
  top: 0.25rem;
  left: 1.5rem;
  width: 16rem;
}

.rivetDiameterSelect {
  width: 10rem;
}

.rivetBodySelect {
  width: 12rem;
}



.bodyDropdown {
  margin-left: -2.5rem;
  grid-column-gap: 10px;
}







.questionHeading {
  font-weight: bold;
}



.diameter {
  position: absolute;
  left: 70%;
  background-color: rgba(100, 100, 0, 0.3);
}


.threadcount {
  position: absolute;
  width: 20%;
  left: 70%;
  top: 54%;
  background-color: rgba(100, 100, 0, 0.3);
  display: flex;
}












// *********
// DESIGN IT
// *********

// .designItContainer {
//   width: 55rem;
// }

// .designIt {
//   background-color: $FFblu0;
//   color: white;
//   padding: 3rem;
//   display: grid;
//   grid-template-columns: 30% 60%;
//   grid-column-gap: 3rem;
// }

// .designItForm>h3 {
//   font-weight: bold;
//   margin-bottom: 3rem;
// }

// .designItFooter {
//   margin-top: 3rem;
// }

// .designItFooter>.btn {
//   float: right;
// }

// .designItImage {
//   width: 18rem;
//   display: inline-block;
//   margin: -3rem;
// }

// ********
// ABOUT US
// ********

// .ryanPic {
//   position: relative;
//   width: 100%;
//   top: 1rem;
//   left: -4rem;
//   height: 25rem;
//   border: solid black;

// }

// .aboutUsContainer {
//   width: 65em;
//   margin: auto;
// }

// .aboutUsBackground {
//   // background-color: white;
// }

// .aboutUsTopper {
//   width: 65em;
//   margin-top: -100px;
// }

// .aboutUsHeaders {
//   width: 65rem;
//   font-size: 2.5rem;
//   font-family: $open-sans;
//   font-weight: bold;
//   padding-bottom: 1rem;
//   background-color: #0A6B7C ;
//   margin-left: -2rem !important;
//   margin-top: 3rem;

// }

// .aboutUsSpacer {
//   width: 65rem;
//   font-size: 2.5rem;
//   font-family: $open-sans;
//   font-weight: bold;
//   padding-bottom: 1rem;
//   background-color: #0A6B7C ;
//   margin-left: -2rem !important;

// }

// .missionStatement {
//   background-image: $FFgrad4;
//   margin-top: 15px;
//   padding: 5rem;
//   color: white;
// }

// .team {
//   margin-top: 15px;
//   padding: 2rem;
//   background-color: white
// }

// .team > h2 {
//   // justify-content: center;
//   padding-left: 5rem;
//   margin-left: -5rem;
//   padding-top: 1rem;
//   color: white;
//   margin-bottom: 2rem;
//   margin-right: 0;
// }
// .teamMemberName {
//   color: #582B7D;
//   font-weight: bold;
//   font-family: $over-the-rainbow;
//   font-size: 2rem ;
//   margin-right: 3rem;
//   margin-top: 2rem;
//   margin-left: -2rem;
// }

// .teamMemberNameHeather {
//   color: #582B7D;
//   font-weight: bold;
//   font-family: $over-the-rainbow;
//   font-size: 2rem ;
//   margin-right: 3rem;
//   margin-top: 2rem;
//   margin-left: -3rem;

// }

// .teamMemberNameChase {
//   color: #582B7D;
//   font-weight: bold;
//   font-family: $over-the-rainbow;
//   font-size: 2rem ;
//   margin-right: 3rem;
//   margin-top: 2rem;
//   margin-left: -2rem;
// }

// .teamMemberNameEvan {
//   color: #582B7D;
//   font-weight: bold;
//   font-family: $over-the-rainbow;
//   font-size: 2rem ;
//   margin-right: 3rem;
//   margin-top: 2rem;
//   margin-left: -1.7rem;
// }

// .jobTitle {
//   color: #582B7D;
//   margin-top: .4rem;
//   font-family: Verdana, Geneva, Tahoma, sans-serif;
//   margin-left: -.5rem;
// }

// .jobTitleHeather {
//   color: #582B7D;
//   margin-top: .4rem;
//   font-family: Verdana, Geneva, Tahoma, sans-serif;
//   font-size: 1rem;
//   margin-left: -3.5rem;
//   white-space: nowrap;
// }

// .jobTitleEvan {
//   color: #582B7D;
//   margin-top: .4rem;
//   font-family: Verdana, Geneva, Tahoma, sans-serif;
//   font-size: 1rem;
//   margin-left: -1.1rem;
//   white-space: nowrap;
// }

// .jobTitleContributors {
//   color: #582B7D;
//   margin-left: -2rem;
//   font-family: cursive;
//   // margin-left: .75rem;
//   font-size: 1.8rem;
//   margin-top: 4rem;
//   white-space: nowrap;
//   font-weight: bold;
// }

// .jobTitleChase {
//   color: #582B7D;
//   margin-top: .4rem;
//   font-family: Verdana, Geneva, Tahoma, sans-serif;
//   // margin-left: .75rem;
//   font-size: 1rem;
//   margin-left: -1rem;
// }

// .teamBio {
//   display: grid;
//   grid-template-columns: 35% 65%;
//   padding: 2rem;
//   background-color: rgba(250, 250, 250, 0.4);
//   border-radius: 25px;
//   margin-bottom: 15px;
// }

// .memberInfo {
//   margin-left: 3rem;
// }

// .teamBioText {
//   color: $FFdkgrey;
//   padding-left: 20px;
//   border-left: 1px solid $FFdkbeige;
// }

// .timBioText {
//   color: $FFdkgrey;
//   padding-left: 20px;
//   border-left: 1px solid $FFdkbeige;
//   padding-top: 10rem;
// }

// .heatherBioText {
//   color: $FFdkgrey;
//   padding-left: 20px;
//   border-left: 1px solid $FFdkbeige;
//   padding-top: 1rem;
// }

// .evanBioText {
//   color: $FFdkgrey;
//   padding-left: 20px;
//   border-left: 1px solid $FFdkbeige;
//   padding-top: 3.5rem;
// }

// .chaseBioText {
//   color: $FFdkgrey;
//   padding-left: 20px;
//   border-left: 1px solid $FFdkbeige;
//   padding-top: 6rem;
// }

// ****************
// ACCOUNT CREATION
// ****************

.verify {
  width: 37rem;
  background-color: $FFblu0;
  color: white;
  font-family: $open-sans;
  padding: 40px;
  margin-top: 350px;
  margin: auto;
  font-size: 120%;
  // font-weight: bold;
  position: relative;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.3);
}

.verify>h2 {
  font-weight: bold;
}

.verify>.btn {
  position: absolute;
  right: 40px;
  bottom: -1.4rem;
}

.onboard {
  width: 37rem;
  background-color: $FFltbeige;
  color: $FFblu1;
  font-family: $open-sans;
  padding: 40px;
  margin-top: 350px;
  margin: auto;
  font-size: 120%;
  // font-weight: bold;
  position: relative;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.3);
  position: relative;
}

.onboard>h2 {
  background-color: $FFred;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  top: -1.3rem;
  margin-bottom: 1.3rem;
  font-size: 1.3rem;
  padding: 0.7rem 1.7rem 0.7rem 1.7rem;
}

// *****
// T & C
// *****

.terms {
  background-color: white;
  width: 80vw;
  margin: 0 auto;
  margin-top: 120px;
  padding: 40px;
  font-family: $open-sans;
  color: black;
  border-radius: 25px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 75px;
}

.termsButton {
  position: absolute;
  right: 45px;
  bottom: -1.4rem;
}

.terms>hr {
  border: 1px solid white;
}

// **********
// CONTACT US
// **********

.contactHeader {
  color: white;
  margin: auto;
  background-image: url("../images/contact.jpg");
  background-size: 45rem 15rem;
  height: 15rem;
  width: 45rem;
  position: relative;
}

.contactPhone {
  color: white;
  position: relative;
  margin-left: 40rem;
}

.contactContainer {
  margin: auto;
  width: 45rem;
  padding: 3rem;
  background-color: $FFblu0;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.3);
}

.contactContainer>h3 {
  font-weight: bold;
}

.contactInfo {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 15% 60%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.contactInfoHeading {
  text-transform: uppercase;
  color: $FFblu3;
  font-size: 0.7rem;
  line-height: 1.6rem;
  font-weight: bold;
  text-align: right;
}

// *******************
// TESTING AND BETA
// *******************

.feedback {
  margin: 0 auto;
  background-color: $FFdkbeige;
  font-weight: bold;
  font-family: $open-sans;
  font-size: 14pt;
  color: $FFdkdkbeige;
  width: 600px;
  text-align: center;
  padding-bottom: 50px;
  display: none;
}

.testdiv {
  background-color: magenta;
  width: 80vw;
  height: 80vh;
  margin: 0 auto;
  margin-top: 150px;
  font-family: serif;
  font-weight: bold;
  color: white;
  font-size: 150%;
}

/* Customize the label (the container) */
.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $FFmdgrey;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: white;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input~.checkmark {
  background-color: $FFltgrey;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked~.checkmark {
  background-color: $FFblu3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 11px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

label>input[type="checkbox"]:checked+* {
  color: white;
  font-weight: bold;
}

#root {
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
}

.landingpageBG>.hero {
  background-image: url("../images/home-bridge-01.jpg");
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  margin-left: unset;
  padding-left: 3rem;
}

// html,
// body,
// #root {
//   height: 100%;
// }

.landingpageBG {
  flex: 1 0 auto;
  min-height: 0;
  height: unset;
  max-width: unset;
  background: none;
}

.navLink .fesTag {
  font-size: 60%;
}

.navbar .landingPageCardTitle {
  font-size: 68%;
}


.reactSelect input {
  opacity: 0;
  width: inherit;
  height: 0;
  position: relative;
  display: inherit;
}

.fesTag {
  transform: translateY(40%) translateX(5%) rotate(-90deg);
  display: block;
  float: left;
  font-size: 64%; // This should be overridden in most cases
  padding-left: 0.35rem;
}

.fesTagHome {
  transform: translateY(35%) translateX(10%) rotate(-90deg);
  margin-top: 0.25rem;
}

.fesTagTopBar {
  transform: translateY(60%) translateX(20%) rotate(-90deg);
}

.featureHeader {
  font-kerning: normal;
  font-size: 2.75rem;
  background-image: $FFgrad1;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top-right-radius: 25px;
  position: relative;
  display: flex;
}

.joiningItHeader {
  margin-left: 80px;
  margin-right: 80px;
}

.findingItHeader {
  width: 55rem;
  height: 5.5rem;
}

.fesBrandedHeader {
  font-family: "Oswald", "sans-serif";
}

.fesBrandedTrademark {
  vertical-align: super;
  font-size: 50%;
  color: white;
}

abbr[title] {
  text-decoration: none !important;
  border-bottom: none;
  cursor: inherit;
}

#hubspotForm {
  width: 40rem;
  height: 50rem;
  margin-top: 2rem !important;
  margin: auto;
  margin-bottom: 15rem;
  //   background-color: #582B7D
}

.loginButton {
  background-color: #052c45 !important;
  font-size: 1.5rem !important;
  border-radius: 1rem !important;
  margin: 1rem;
}

.loginButton :hover {
  background-color: #582b7d !important;
  font-size: 1.5rem !important;
  border-radius: 1rem !important;
  margin: 1rem;
  transform: scale(1.1);
}

.requiredFieldIndicator {
  color: red;
}

.card a:hover {
  color: unset !important;
}

.solutionDisclaimer {
  display: block;
  text-align: center;
  font-size: 0.8rem;
  color: grey;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 0.3rem;
}

.subheading {
  padding-bottom: 1rem;
  border-bottom: 1px solid #d6dbc2;
  font-size: 1.4rem;
  color: #052c45;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.subheading div[role=presentation] {
  display: inline
}

/****************************************
* Card styling, probably from bootstrap.
* todo: Delete unused stuff
*****************************************/

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: red;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group>.card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/****************************************
* Carousel styling, probably from bootstrap.
* todo: Delete unused stuff
*****************************************/

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@import "_accountCreation.module.scss";
@import "_utilityStyles.module.scss";
@import "_aboutUs.module.scss";
@import "_designIt.module.scss";
@import "_findIt.module.scss";
@import "_joinIt.module.scss";
@import "_articles.module.scss";
@import "_learningIt.module.scss";
@import "_torqueCalculator.module.scss";
@import "_topBar.module.scss";
@import "_home.module.scss";
@import "_button.module.scss";
@import "_choosePlan2.module.scss";
@import "_footer.module.scss";
@import "_introCourses.module.scss";
@import "_missionStatement.module.scss";
@import "_tooltip.module.scss";

@import "carousel-style-override.scss";